import React, {Component} from "react";
import {CounterProps} from "../../../../models/BannerModel";
import {Counter} from "./Counter";
import './CounterLayout1.scss';
import ScrollAnimation from "react-animate-on-scroll";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";


class CounterLayout1 extends Component<CounterProps, any> {

    sectionStyle: any = {
        backgroundImage: `url(${this.props?.data?.bgImage.url})`,
    }

    render() {
        return <section id={this.props?.htmlId}
                        className={`${this.props?.themeClass} counter_l1`} style={this.props?.data?.bgImage.url ? this.sectionStyle : {}}>
            <div id="counter">
                <div className="container">
                    <div className="row justify-content-center mb-3">
                        <ScrollAnimation animateIn='fadeInUp'>
                            <HeadingTitles heading={this.props.data['title']} classesObject={{
                                headingClass: 'heading-style mb-0',
                                descriptionClass: 'sub-title text-muted'
                            }} layout="layout_1" headingTag="h2" alignment="center"/>
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation animateIn='fadeInLeft'>
                        <div className="row justify-content-center">
                            {
                                this.props?.data?.counters?.map((counter: any) => {
                                    return <Counter title={counter?.title} target={counter?.value}
                                                    suffix={counter?.suffix}/>
                                })
                            }
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </section>;
    }
}

export default CounterLayout1;
