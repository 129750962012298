import {SectionHeaderProps} from "../../../../../models/BannerModel";
import {Component} from "react";


class SectionHeader2 extends Component<SectionHeaderProps, any> {

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} p-0 pt-5 testingFontFamily d-flex flex-column justify-content-center`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="section-heading text-center mb-8">
                            <h2 className="heading-style">{this.props.data['title']}</h2>
                            <p className="sub-title">{this.props.data['subtitle']}</p>
                        </div>
                    </div>
                    <hr/>
                </div>
            </section>
        );
    }
}

export default SectionHeader2;
