import React, {useEffect, useState} from "react";
import "./HeaderLayout3.scss";
import {MenuItems} from "./MenuItems/MenuItems";
import {useLocation} from "react-router";
import {Container, Nav, Navbar, Offcanvas} from "react-bootstrap";
import {Navbrand} from "./Navbrand/Navbrand";
import "bootstrap-icons/font/bootstrap-icons.css";
import {UniversalReadMoreButton} from "../../../../components/UniversalReadMoreButton/UniversalReadMoreButton";
import {TargetLocation} from "../../../../components/commonFunctions/header/TargetLocation";
import ScrollAnimation from "react-animate-on-scroll";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import LoaderButton from "../../../../components/LoaderButton";

export const HeaderLayout3 = (props: any) => {
    const [state, setState] = useState<Header1State>({
        name: '',
        email: '',
        menuItems: props.data?.menus,
        activeMenu: ''
    });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
        TargetLocation(props?.data?.sticky, location, props?.htmlId).then((target) => {
            setState((prevState: any) => ({...prevState, activeMenu: target}));
        })
    }, [location.pathname, location.hash]);

    return (
        <div className="header_l3">
            {['lg'].map((expand, index) => (
                <Navbar expanded={isOpen} id={props.htmlId} key={index}
                        className={`${props.themeClass} middle_navlayout p-lg-0 w-100`}
                        expand={expand}>
                    <Container fluid className="middle_navlayout__menu_wrapper">
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}
                                       onClick={() => setIsOpen(!isOpen)}/>
                        <div className="middle_navlayout__border_bottom2">
                            <Navbrand {...props} />
                        </div>
                        {
                            props?.data?.bookNow?.active ?

                                <div className="d-flex align-items-center justify-content-center media_button">
                                    <UniversalReadMoreButton payload={props?.data?.bookNow}
                                                             pathName={props?.location?.pathname ?? ""}
                                                             siteId={props?.channelId}/>
                                </div> : ''}
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            className="middle_navlayout__canvas_wrapper">
                            <div className="container-fluid middle_navlayout__border_bottom d-none d-lg-block">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-center justify-content-center">
                                        <div className="contact-icons mt-2">
                                            {props?.data?.mobile ?
                                                <a href={`tel:+91${props?.data?.mobile}`}>
                                                    <i className="bi bi-telephone me-2"></i>
                                                    <span>{props?.data?.mobile}</span>
                                                </a> : null}
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex align-items-center justify-content-center">
                                        <Navbrand {...props} />
                                    </div>
                                    {props?.data?.bookNow?.active ?
                                        <div className="col-4 d-flex align-items-center justify-content-center">
                                            <UniversalReadMoreButton payload={props?.data?.bookNow}
                                                                     pathName={props?.location?.pathname ?? ""}
                                                                     siteId={props?.channelId}/>
                                        </div>
                                        : ''}
                                </div>
                            </div>

                            <Offcanvas.Header className="middle_navlayout__canvas_header">
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <Navbrand {...props} />
                                </Offcanvas.Title>
                                <div onClick={() => setIsOpen(!isOpen)} aria-hidden="true"
                                     className="cursor-p close_icon fs-42">&times;</div>
                            </Offcanvas.Header>

                            <Offcanvas.Body className="align-items-lg-center middle_navlayout__canvas_body">
                                <Nav className="justify-content-center flex-grow-1">
                                    <MenuItems menuItemsList={state.menuItems} pathName={props.location.pathname}
                                               toggleMenu={() => setIsOpen(false)}
                                               pageId={props.match.params.id} ulClassName={"main-menu menu"}
                                               activeMenu={state.activeMenu}/>
                                </Nav>
                                <div className="contact-icons d-block d-md-none text-center">
                                    <hr/>
                                    {props?.data?.mobile ?
                                        <a href={`tel:+91${props?.data?.mobile}`}>
                                            <i className="bi bi-telephone me-2"></i>
                                            <span>{props?.data?.mobile}</span>
                                        </a> : null}
                                </div>
                            </Offcanvas.Body>

                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>
    );

}

export default HeaderLayout3;