import React, {Fragment, useEffect, useState} from "react";
import "./Header_common.scss";
import {useLocation} from "react-router";
import {MenuItems} from "./MenuItems/MenuItems";
import {Container, Nav, Navbar, Offcanvas} from "react-bootstrap";
import {Navbrand} from "./Navbrand/Navbrand";
import {TargetLocation} from "../../../../components/commonFunctions/header/TargetLocation";
import {UniversalReadMoreButton} from "../../../../components/UniversalReadMoreButton/UniversalReadMoreButton";
import HeaderRibbon from "../headerRibbon/HeaderRibbon";

export const HeaderLayout1 = (props: any) => {
    const [state, setState] = useState<Header1State>({
        name: '',
        email: '',
        menuItems: props.data?.menus,
        activeMenu: ''
    });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
        TargetLocation(props?.data?.sticky, location, props?.htmlId).then((target) => {
            setState((prevState: any) => ({...prevState, activeMenu: target}));
        })
        checkIfHomePage();
        console.log('#27', isOpen)
    }, [location.pathname, location.hash]);

    const checkIfHomePage = () => {
        let path = location.pathname;
        let isHosted: boolean = window.location.hostname !== "localhost";
        let slashes = [];

        slashes = path.split('/');
        console.log('36', isHosted, path === '/home', (slashes.length - 1) === 2, path.includes('home'), path, isOpen)
        return isHosted ? path === '/home' || path === '/' : path.includes('home') || (slashes.length - 1) === 2;
    }

    return (<Fragment>
        {['lg'].map((expand) => (
            <Navbar expanded={isOpen} id={props.htmlId}
                    className={`${props.themeClass} ${checkIfHomePage() ? props?.data.transparent ?? true ? 'isTransparent' : '' : ''} common_navlayout`}
                    expand={expand}>
                <Container className="left_navlayout__menu_wrapper">
                    <Navbrand {...props} />
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}
                                   onClick={() => setIsOpen(!isOpen)}/>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"

                        className="left_navlayout__canvas_wrapper desktop_menu">
                        <Offcanvas.Header className="left_navlayout__canvas_header">
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                <Navbrand {...props} />
                            </Offcanvas.Title>
                            <div onClick={() => setIsOpen(!isOpen)} aria-hidden="true"
                                 className="cursor-p close_icon fs-42">&times;</div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav
                                className="justify-content-end align-items-center flex-grow-1 left_navlayout__canvas_body">

                                <MenuItems menuItemsList={state.menuItems} pathName={props.location.pathname}
                                           toggleMenu={() => setIsOpen(false)}
                                           pageId={props.match.params.id} ulClassName={"main-menu menu"}
                                           activeMenu={state.activeMenu}/>

                            </Nav>
                            {props?.data?.bookNow?.active ?
                                <div className="thm_btn">
                                    <UniversalReadMoreButton payload={props?.data?.bookNow}
                                                             pathName={props?.location?.pathname ?? ""}
                                                             siteId={props?.channelId}/>
                                </div>
                                : ''}
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        ))}
    </Fragment>);

}

export default HeaderLayout1;