import React, {useEffect, useState} from "react";
import "../gallery/GalleryLayout1.scss"
import "./MediaSelector.scss";
import Carousel from "react-bootstrap/Carousel";
import LazyLoad from "react-lazyload";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import {UniversalReadMoreButton} from "../../../../components/UniversalReadMoreButton/UniversalReadMoreButton";
import {useLocation} from "react-router";

export const enum MediaSelectedType {
    IMAGE = "image",
    VIDEO = "youtube",
    IMAGE_SLIDER = "slides",
    INFO_IMAGE = "infoImage",
    MARKUP = "markdown",
    EMPTY = "empty",
    CLICK_TO_ACTION = "clickToAction",
}

export const MediaSelector = (props: { data: any, htmlId?: any }) => {
    const [language, setLanguage] = useState<"en" | "hi">("en");
    const location = useLocation();
    const content = {
        en: {
            content: "I pledge to promote cancer awareness. I will educate people about the causes, symptoms, and prevention of cancer. I will take the initiative to encourage everyone to undergo regular health check-ups and adopt a healthy lifestyle to prevent cancer. I will actively participate in this mission and make every possible effort to spread awareness about cancer in society.\n\nThrough this pledge, we commit together to take the necessary steps to fight and prevent cancer.\n\n1. Maintaining a Balanced Diet:\nWe pledge to consume healthy foods such as fruits, vegetables, grains, and proteins that provide the necessary energy and nutrients to build and strengthen our bodies.\n\n2. Engaging in Regular Physical Exercise:\nWe pledge to keep our bodies active every day through activities such as running, walking, playing, or dancing, which help keep our muscles and heart healthy.\n\n3. Avoiding Tobacco Products and Alcohol Consumption:\nWe pledge to encourage those around us to stay away from smoking and drinking alcohol, and we will also refrain from these as they harm our bodies and hinder proper functioning.\n\n4. Protecting Ourselves from Excessive Sunlight:\nWe pledge to wear hats in the sun and avoid spending too much time under direct sunlight to keep our skin healthy and reduce the risk of sunburn or other skin issues."
        },
        hi: {
            content: "मैं शपथ लेता/लेती हूं कि मैं कैंसर जागरूकता को बढ़ावा दूंगा/दूंगी। मैं लोगों को कैंसर के कारणों, लक्षणों, और बचाव के तरीकों के बारे में शिक्षित करूंगा/करूंगी। मैं इस बात की पहल करूंगा करूंगी कि हम सभी नियमित स्वास्थ्य जांच करवाएं और स्वस्थ जीवनशैली अपनाएं ताकि कैंसर से बचा जा सके। मैं इस मिशन में अपनी पूरी सहभागिता दूंगा/दूंगी और समाज में कैंसर के प्रति जागरूकता फैलाने के लिए हर संभव प्रयास करूंगा/करूंगी। इस शपथ के द्वारा, हम एक साथ मिलकर कैंसर से लड़ने और इससे बचने के लिए जरूरी कदम उठाने की प्रतिबद्धता जताते हैं।\n" +
                "\n" +
                "1. संतुलित आहार बनाए रखना : हम शपथ लेते हैं कि अपने शरीर को बढ़ाने और मजबूत करने के लिए जरूरी ऊर्जा और पोषक तत्व देने वाले फल, सब्जियाँ, अनाज और प्रोटीन जैसे स्वस्थ खाद्य पदार्थों का सेवन करेंगे।\n" +
                "\n" +
                "2. नियमित शारीरिक व्यायाम करना : हम शपथ लेते हैं कि हर दिन दौड़ना, चलना, खेलना या नृत्य जैसी गतिविधियों के माध्यम से अपने शरीर को सक्रिय रखेंगे जिससे हमारी मांसपेशियाँ और हृदय स्वस्थ रहे।\n" +
                "\n" +
                "3. तंबाकू उत्पादों और शराब के सेवन से बचना : हम शपथ लेते हैं कि अपने आस पास के लोगों को धूम्रपान और शराब पीने से दूर रहने के लिए प्रेरित करेंगे और खुद भी इनसे दूर रहेंगे क्योंकि ये हमारे शरीर को नुकसान पहुंचाते हैं और ठीक से काम करने से रोक सकते हैं।\n" +
                "\n" +
                "4. अत्यधिक धूप से खुद की सुरक्षा करना : हम शपथ लेते हैं कि धूप में टोपी पहनेंगे और अधिक धूप में समय बिताने से बचेंगे ताकि हमारी त्वचा ठीक रहे और सनबर्न या अन्य त्वचा की समस्याओं का खतरा कम हो।\n" +
                "4 फरवरी 2025 विश्व कैंसर दिवस"
        }
    };


    useEffect(() => {
        setSizeCLass(props?.data?.size);
        console.log('----34----', props.data);
    }, [])
    useEffect(() => {
        console.log('25', props.data?.showTitle);
    }, [props]);
    const [state, setState] = useState({
        sizeClass: props?.data?.size,
    });

    const setSizeCLass = (size: string) => {
        if (size === 'XS') {
            setState(() => ({sizeClass: 25}));
        } else if (size === 'S') {
            setState(() => ({sizeClass: 50}));
        } else if (size === 'M') {
            setState(() => ({sizeClass: 75}));
        } else if (size === 'L') {
            setState(() => ({sizeClass: 100}));
        }
    }
    const MediaType = () => {
        switch (props.data?.type) {
            case MediaSelectedType.VIDEO:
                let youtubeId = props?.data?.content;
                youtubeId = youtubeId.substring('https://www.youtube.com/watch?v='.length);
                let muted: string = "";
                if (props?.data?.mute) {
                    muted = "&mute=1";
                } else {
                    muted = "";
                }
                return <div className="iframe-container">
                    <iframe
                        src={`https://www.youtube.com/embed/${youtubeId.trim()}?autoplay=${props?.data?.autoplay ? "1" : "0"}${muted.trim()}`}
                        title="YouTube video player" frameBorder="0"
                        allow={`accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture`}
                        allowFullScreen></iframe>
                </div>

            case MediaSelectedType.IMAGE:
                return <LazyLoad offset={100} className="d-flex justify-content-center h-100"><img
                    style={{width: `${state.sizeClass ? state.sizeClass : 75}%`}}
                    src={props.data?.content?.url} loading='lazy' alt={""}/></LazyLoad>
            case MediaSelectedType.IMAGE_SLIDER:
                return <Carousel indicators={false} controls={false}>
                    {props?.data.content.map((image: any, i: any) =>
                        <Carousel.Item className="flex-img" interval={props.data?.delay}>
                            <LazyLoad offset={100} className="d-flex justify-content-center h-100">
                                <img className="img-fluid d-block"
                                     style={{height: `${state.sizeClass ? state.sizeClass : 50}vh`}}
                                     src={props.data?.content[i]?.url} alt={i + 1} loading='lazy'/>
                            </LazyLoad>
                        </Carousel.Item>
                    )}
                </Carousel>
            case MediaSelectedType.MARKUP:
                return <div>
                    {props.data.enableLanguageSwitcher ?
                        <div className={"language_convert"}>
                            <div className="language-switcher">
                                <button
                                    className={`switch-button ${language === "en" ? "active theme_text" : ""}`}
                                    onClick={() => setLanguage("en")}
                                >
                                    English
                                </button>
                                <button
                                    className={`switch-button ${language === "hi" ? "active theme_text" : ""}`}
                                    onClick={() => setLanguage("hi")}
                                >
                                    हिंदी
                                </button>
                            </div>
                            <div className={"mt-3"}>
                                <p style={{textAlign: "justify"}}
                                    dangerouslySetInnerHTML={{
                                        __html: content[language as "en" | "hi"].content.replace(/\n/g, "<br />"),
                                    }}
                                ></p>
                            </div>
                        </div>
                        :


                        <div>
                            <p dangerouslySetInnerHTML={{__html: props?.data?.content?.html}}
                               className="title_description"
                               style={{margin: "0px", textAlign: "justify"}}/>
                        </div>
                    }


                    <div className="section-header d-flex align-items-center">
                        {props.data?.showTitle ? <div className="float-start">
                            <HeadingTitles heading={props.data['title']} classesObject={{
                                headingClass: 'heading-style',
                                descriptionClass: 'mini-title'
                            }} headingTag="h2" description={props.data['subtitle']} alignment="start"
                                           layout="layout_2"/>
                        </div> : <div></div>}
                    </div>


                </div>

            case MediaSelectedType.EMPTY:
                return <div className="flexImage-img img-fluid img-responsive"></div>
            case MediaSelectedType.INFO_IMAGE:
                return <div>
                    <div className="position-relative d-block zoom-effect-1" style={{height: '250px'}}>
                        <LazyLoad offset={100}>
                            <img
                                style={{width: `${state.sizeClass ? state.sizeClass : 75}%`}}
                                src={props.data?.content?.url} loading='lazy' alt={""}/>
                        </LazyLoad>
                    </div>
                    <div className="mt-4 head_wrap">
                        <h3 className="heading-style">{props.data?.title}</h3>
                        {props.data?.title ?
                            <span className="label-line"></span> : ''}
                        {
                            props.data?.desc?.html ?
                                <div dangerouslySetInnerHTML={{__html: props.data?.desc?.html || ''}}
                                     className={'mt-1 mx-md-3 product__description mini-title'}></div>
                                :

                                <p className="sub-title">{props.data?.description}</p>

                        }

                    </div>
                </div>
            case MediaSelectedType.CLICK_TO_ACTION:
                return <div>
                    <div
                        className={`mb-4 ${props.data?.content?.url ? "card shadow-sm" : ""}`}
                        style={{
                            width: `${state.sizeClass || 75}%`,
                            margin: "0 auto",
                        }}
                    >
                        {props.data?.content?.url && (
                            <LazyLoad offset={100} className="m-3 rounded position-relative d-block zoom-effect-1">
                                <img
                                    style={{width: "100%", maxHeight: "280px"}}
                                    src={props.data.content.url}
                                    loading="lazy"
                                    alt={props.data?.title || "Image"}
                                    className="card-img-top object-fit-fill"
                                />
                            </LazyLoad>
                        )}
                        <div className={`${props.data?.content?.url ? "card-body" : ""}`}>
                            <h3 className="heading-style mb-4 text-center">{props.data?.title}</h3>
                            <div
                                dangerouslySetInnerHTML={{__html: props.data?.desc?.markup || ""}}
                                className="mt-1 product__description"
                            ></div>
                            {props.data?.description && (
                                <p className="sub-title mt-3">{props.data.description}</p>
                            )}

                            <UniversalReadMoreButton
                                payload={props?.data?.action}
                                pathName={props?.data?.location?.pathname || ""}
                                siteId={props?.htmlId}
                            />
                        </div>
                    </div>

                </div>
            default:
                return <div></div>
        }
    }
    return (<div className="video_wrapper w-100 h-100 d-flex justify-content-center align-items-center">
        {MediaType()}
    </div>)
}
