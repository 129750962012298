import React, {Component, FormEvent, RefObject} from "react";
import {PledgeFormProps} from "../../../../models/BannerModel";
import axiosClient from "../../../../config/AxiosRestClient";
import toast from "react-hot-toast";
import LoaderButton from "../../../../components/LoaderButton";
import pledgeFormImg from "../../../../assets/css/images/WhatsApp Image 2024-11-08 at 17.49.20.jpeg";
import "./PledgeForm.scss";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import {FaFacebook, FaWhatsapp, FaInstagram, FaTwitter} from "react-icons/fa";

interface PledgeFormBscState {
    processing: boolean;
    messageSent: boolean;
    formId: number | null;
    name: string;
    gender: string;
    pincode: string;
    state: string;
    country: string;
    district: string;
    email: string;
    mobile: string;
    dob: string;
    pledgeFormURL: string;
}

class PledgeForm extends Component<PledgeFormProps, PledgeFormBscState> {

    form: RefObject<HTMLFormElement>;

    constructor(props: PledgeFormProps) {
        super(props);
        this.state = {
            processing: false,
            messageSent: false,
            formId: null,
            name: "",
            gender: "",
            district: "",
            state: "",
            country: "",
            pincode: "",
            email: "",
            dob: "",
            mobile: "",
            pledgeFormURL: "",
        };
        this.form = React.createRef();
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        this.setState({[name]: value} as unknown as PledgeFormBscState);
    };

    getPayload = (): any => {
        const {email, name, gender, mobile, district, state, country, pincode, dob} = this.state;
        return {email, name, gender, mobile, district, state, country, pincode, dob};
    };

    submitPledgeForm = (event: FormEvent) => {
        event.preventDefault();
        this.setState({processing: true});

        axiosClient
            .post(`/listingfy-service/pledge`, this.getPayload())
            .then((res) => {
                if (res.status === 200) {
                    const url = res.data.pledgeFormURL; // API provides certificate URL
                    this.setState({
                        processing: false,
                        messageSent: true,
                        formId: res.data.id,
                        pledgeFormURL: res.data.pledgeFormURL,
                    });
                    console.log('Pledge Form URL:', res.data.pledgeFormURL);
                    toast.success("Form submitted successfully");
                } else {
                    this.setState({processing: false});
                    toast.error("Unknown error submitting form");
                }
            })
            .catch((error) => {
                console.error("Pledge Form Submission Error:", error);
                this.setState({processing: false});
                toast.error("Form already submitted by user");
            });
    };

    downloadPledgeForm = () => {
        const {formId} = this.state;
        if (!formId) {
            console.error("Form ID is required to download the pledge form");
            return;
        }

        // Step 1: Get the file URL from the backend
        axiosClient
            .get(`listingfy-service/pledge-form/${formId}/download`, {
                responseType: 'json', // Expect JSON response
            })
            .then((response) => {
                console.log(response, '..response');

                const fileUrl = response.data.url; // Extract the file URL
                if (!fileUrl) {
                    console.error("No URL found in the response");
                    return;
                }
                // console.log(fileUrl);

                // Step 2: Fetch the file content using the URL
                axios.get(fileUrl, {
                    responseType: 'blob', // Fetch the binary content
                })
                    .then((fileResponse) => {
                        // Create a Blob object from the file data
                        const blob = new Blob([fileResponse.data], {type: fileResponse.headers['content-type']});

                        // Step 3: Create a download link and trigger the download
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'PledgeCertificate.pdf'); // Set the downloaded file name
                        document.body.appendChild(link);
                        link.click();

                        // Cleanup
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((fileError) => {
                        console.error("Error fetching file content:", fileError.message);
                    });
            })
            .catch((error) => {
                if (error.response) {
                    console.error("Server responded with error:", error.response.status, error.response.data);
                    if (error.response.headers['content-type'] === 'application/json') {
                        alert("An error occurred: " + JSON.stringify(error.response.data));
                    }
                } else {
                    console.error("Error fetching file URL:", error.message);
                }
            });
    };


    validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const errorMessage = document.getElementById("emailError");

        // Check if the element exists to avoid null errors
        if (errorMessage) {
            if (!email.endsWith("@gmail.com")) {
                errorMessage.textContent = "Fill @gmail.com to get valid";
            } else {
                errorMessage.textContent = ""; // Clear the error message if valid
            }
        }

        this.setState({email}); // Update the state with the input value
    };


    renderForm() {
        const {country} = this.state;
        return (
            <div>
                <div className="row justify-content-center mb-3">
                    <ScrollAnimation animateIn='fadeInUp'>
                        <HeadingTitles heading={this.props.data['title']} classesObject={{
                            headingClass: 'heading-style mb-0 mx-2',
                            descriptionClass: 'sub-title text-muted'
                        }} layout="layout_1" headingTag="h2" alignment="center"/>
                    </ScrollAnimation>
                </div>
                <div className="pledge-form text-center">
                    <form id="contact-form" role="form" onSubmit={this.submitPledgeForm}>
                        <div className="row">
                            <div className="user__details">
                                <div className="input__box">
                                    <span className="details">Name | नाम <span className="text-danger">*</span></span>
                                    <input className={'input'} type="text"
                                           placeholder="E.g: John Smith"
                                           name={"name"}
                                           required
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                                <div className="input__box">
                                    <span className="details">Email | ईमेल </span>
                                    <input
                                        className="input"
                                        type="email"
                                        placeholder="email**11@gmail.com"
                                        name="email"
                                        required onChange={(event) => this.validateEmail(event)}
                                    />
                                </div>


                                <div className="input__box">
                                    <span className="details">Date of Birth | जन्मतिथि <span
                                        className="text-danger">*</span></span>
                                    <input
                                        className="input"
                                        type="date"
                                        placeholder="02/06/2011"
                                        name="dob"
                                        required
                                        max={new Date().toISOString().split("T")[0]} // Sets max to today's date
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                </div>

                                <div className="input__box">
                                    <span className="details">Gender | लिंग <span
                                        className="text-danger">*</span></span>
                                    <div className={'d-flex'}>
                                        <div className={'d-flex mt-3 mb-3'}>
                                            <input type="radio" id={'gender'} value='MALE'
                                                   name="gender"
                                                   className={'me-2'}
                                                   required
                                                   onChange={event => this.handleChange(event)}/>
                                            <label id={'gender'}>Male</label>
                                        </div>
                                        <div className={'d-flex ms-4 mt-3 mb-3'}>
                                            <input type="radio" id={'gender'} value='FEMALE'
                                                   name="gender"
                                                   className={'me-2'}
                                                   onChange={event => this.handleChange(event)}/>
                                            <label id={'gender'}>Female</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input__box">
                                    <span className="details">
                                        Phone Number | मोबाइल <span className="text-danger">*</span>
                                    </span>
                                    <input
                                        type="tel"
                                        className="input"
                                        placeholder="98*** ***18"
                                        name="mobile"
                                        required
                                        pattern="[0-9]{10}" // Ensures exactly 10 digits
                                        maxLength={10} // Prevents input beyond 10 characters
                                        onInvalid={(event) => {
                                            const target = event.target as HTMLInputElement; // Type assertion
                                            target.setCustomValidity("10 digits required");
                                        }}
                                        onInput={(event) => {
                                            const target = event.target as HTMLInputElement; // Type assertion
                                            target.setCustomValidity(""); // Clear custom error message
                                            target.value = target.value.replace(/[^0-9]/g, ""); // Allow only digits
                                        }}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                </div>


                                <div className="input__box">
                                    <span className="details">Country | देश <span
                                        className="text-danger">*</span></span>
                                    <select
                                        name="country"
                                        className="input"
                                        value={country}
                                        required
                                        onChange={(event) => this.handleChange(event)}
                                    >
                                        <option value="">Select Country | देश चुनें</option>
                                        <option value="India">India | भारत</option>
                                        <option value="Nepal">Nepal | नेपाल</option>
                                        <option value="United States">United States | संयुक्त राज्य अमेरिका</option>
                                        <option value="Canada">Canada | कनाडा</option>
                                        <option value="Australia">Australia | ऑस्ट्रेलिया</option>
                                        <option value="United Kingdom">United Kingdom | यूनाइटेड किंगडम</option>
                                        <option value="Other">Other | अन्य</option>
                                    </select>
                                </div>

                                {country === "India" ? (
                                    <div className="input__box">
                                        <span className="details">State | राज्य <span
                                            className="text-danger">*</span></span>
                                        <select name="state" className={'input'}
                                                required
                                                onChange={this.handleChange}>
                                            <option value="">Select State / राज्य चुनें</option>
                                            <option value="Andhra Pradesh">Andhra Pradesh / आंध्र
                                                प्रदेश
                                            </option>
                                            <option value="Arunachal Pradesh">Arunachal Pradesh /
                                                अरुणाचल
                                                प्रदेश
                                            </option>
                                            <option value="Assam">Assam / असम</option>
                                            <option value="Bihar">Bihar / बिहार</option>
                                            <option value="Chhattisgarh">Chhattisgarh / छत्तीसगढ़
                                            </option>
                                            <option value="Goa">Goa / गोवा</option>
                                            <option value="Gujarat">Gujarat / गुजरात</option>
                                            <option value="Haryana">Haryana / हरियाणा</option>
                                            <option value="Himachal Pradesh">Himachal Pradesh /
                                                हिमाचल
                                                प्रदेश
                                            </option>
                                            <option value="Jharkhand">Jharkhand / झारखंड</option>
                                            <option value="Karnataka">Karnataka / कर्नाटक</option>
                                            <option value="Kerala">Kerala / केरल</option>
                                            <option value="Madhya Pradesh">Madhya Pradesh / मध्य
                                                प्रदेश
                                            </option>
                                            <option value="Maharashtra">Maharashtra / महाराष्ट्र
                                            </option>
                                            <option value="Manipur">Manipur / मणिपुर</option>
                                            <option value="Meghalaya">Meghalaya / मेघालय</option>
                                            <option value="Mizoram">Mizoram / मिजोरम</option>
                                            <option value="Nagaland">Nagaland / नागालैंड</option>
                                            <option value="Odisha">Odisha / ओडिशा</option>
                                            <option value="Punjab">Punjab / पंजाब</option>
                                            <option value="Rajasthan">Rajasthan / राजस्थान</option>
                                            <option value="Sikkim">Sikkim / सिक्किम</option>
                                            <option value="Tamil Nadu">Tamil Nadu / तमिलनाडु
                                            </option>
                                            <option value="Telangana">Telangana / तेलंगाना</option>
                                            <option value="Tripura">Tripura / त्रिपुरा</option>
                                            <option value="Uttar Pradesh">Uttar Pradesh / उत्तर
                                                प्रदेश
                                            </option>
                                            <option value="Uttarakhand">Uttarakhand / उत्तराखंड
                                            </option>
                                            <option value="West Bengal">West Bengal / पश्चिम बंगाल
                                            </option>
                                            <option value="Andaman and Nicobar Islands">Andaman and
                                                Nicobar
                                                Islands / अंडमान और निकोबार द्वीप
                                            </option>
                                            <option value="Chandigarh">Chandigarh / चंडीगढ़</option>
                                            <option
                                                value="Dadra and Nagar Haveli and Daman and Diu">Dadra
                                                and Nagar Haveli and Daman and Diu / दादरा और नगर
                                                हवेली और
                                                दमन और दीव
                                            </option>
                                            <option value="Delhi">Delhi / दिल्ली</option>
                                            <option value="Lakshadweep">Lakshadweep / लक्षद्वीप
                                            </option>
                                            <option value="Puducherry">Puducherry / पुदुचेरी
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <div className="input__box">
                                        <span className="details">State / Province | राज्य / प्रांत <span
                                            className="text-danger">*</span></span>
                                        <input type="text" placeholder="E.g: California" name="state"
                                               className={'input'}
                                               required
                                               onChange={this.handleChange}/>
                                    </div>
                                )}

                                <div className="input__box">
                                                    <span
                                                        className="details">City/Town/Village | शहर / कस्बा / गांव</span>
                                    <input type="text" placeholder="E.g: U.P" name={"district"}
                                           className={'input'}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                                <div className="input__box">
                                    <span className="details">Pincode | पिन कोड</span>
                                    <input type="text" placeholder="E.g: 282010"
                                           name={"pincode"}
                                           className={'input'}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex mx-2 justify-content-center mt-3 button button_theme py-2 text-white overflow-hidden position-relative">
                            <LoaderButton loading={this.state.processing} label={'Take Pledge'}
                                          cssName={"btn button_theme"}/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    renderSuccessScreen() {
        const {pledgeFormURL} = this.state;
        console.log('273 ->>>>>', pledgeFormURL);

        return (
            <div className="text-center">
                <h2 className="text-success">Congratulations!</h2>
                <p>Your pledge form has been successfully submitted. Here is your certificate:</p>

                {pledgeFormURL ? (
                    <div style={{height: '400px', width: '100%'}}>
                        <iframe
                            src={pledgeFormURL}
                            title="PDF Preview"
                            width="80%"
                            height="100%"
                            style={{border: 'none'}}
                        ></iframe>
                    </div>
                ) : (
                    <p className="text-danger">Certificate preview is unavailable.</p>
                )}
                <div
                    onClick={this.downloadPledgeForm} // Add onClick handler to trigger download
                    className="mx-3 d-flex justify-content-center mt-3 button button_theme py-2 text-white overflow-hidden position-relative"
                    style={{cursor: "pointer"}} // Adding cursor pointer to make it clear it's clickable
                >
                    <i className="bi bi-download"></i>
                    <LoaderButton
                        loading={this.state.processing}
                        label={"Download Certificate"}
                        cssName={"btn button_theme"}
                    />
                </div>
            </div>
        );
    }

    renderPledgeTemplate() {
        const pledgeTemplateURL = "https://s3.carvia.tech/dl/user-profiles/f9c70-18e54c1.jpeg"; // Publicly hosted image URL

        return (
            <div className="text-center bg-white rounded pt-3 pb-4 px-md-4">
                {/* Image */}
                <div className="image-container">
                    <img
                        src={pledgeTemplateURL}
                        className="pledge-image"
                        alt="Pledge Template"
                    />
                </div>
                <div className="share-container">
                    <h3 className="share-label">Share via</h3>
                    {/* Social Icons */}
                    <div className="social-icons">
                        {/* Facebook */}
                        <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                pledgeTemplateURL
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon facebook"
                        >
                            <FaFacebook/>
                        </a>

                        {/* WhatsApp */}
                        <a
                            href={`https://wa.me/?text=${encodeURIComponent(
                                `Check this out: ${pledgeTemplateURL}`
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon whatsapp"
                        >
                            <FaWhatsapp/>
                        </a>

                        {/* Instagram */}
                        <a
                            href={pledgeTemplateURL}
                            download
                            className="social-icon instagram"
                        >
                            <FaInstagram/>
                        </a>
                        {/* Twitter */}
                        <a
                            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                                pledgeTemplateURL
                            )}&text=${encodeURIComponent("Check this out!")}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon twitter"
                        >
                            <FaTwitter />
                        </a>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <section
                id={this.props.htmlId}
                className={`${this.props.themeClass} pledgeForm ptb_80 testingFontFamily`}
            >
                <div className="container">
                    <div className="row">
                        {/* Conditionally render the left column */}
                        {this.state.messageSent ? (
                            <div className="col-md-6 d-flex justify-content-center align-items-center mb-5">
                                {this.renderPledgeTemplate()}
                            </div>
                        ) : (
                            <div className="col-md-6 d-flex justify-content-center align-items-center mb-5">
                                <img src={pledgeFormImg} className="w-100 rounded"/>
                            </div>
                        )}
                        {/* Right column remains unchanged */}
                        <div className="col-md-6">
                            <div className="bg-white rounded pt-3 pb-4 px-md-4">
                                {this.state.messageSent ? this.renderSuccessScreen() : this.renderForm()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

}

export default PledgeForm;


{/*CODE FOR DOWNLOAD PLEDGE CERTIFICATE */
}

{/*<h2 className="text-success">Congratulations!</h2>*/
}
{/*<p>Your pledge form has been successfully submitted. Here is your certificate:</p>*/
}

{/*{pledgeFormURL ? (*/
}
{/*    <div style={{height: '400px', width: '100%'}}>*/
}
{/*        <iframe*/
}
{/*            src={pledgeFormURL}*/
}
{/*            title="PDF Preview"*/
}
{/*            width="80%"*/
}
{/*            height="100%"*/
}
{/*            style={{border: 'none'}}*/
}
{/*        ></iframe>*/
}
{/*    </div>*/
}
{/*) : (*/
}
{/*    <p className="text-danger">Certificate preview is unavailable.</p>*/
}
{/*)}*/
}
{/*<div*/
}
{/*    onClick={this.downloadPledgeForm} // Add onClick handler to trigger download*/
}
{/*    className="mx-3 d-flex justify-content-center mt-3 button button_theme py-2 text-white overflow-hidden position-relative"*/
}
{/*    style={{cursor: "pointer"}} // Adding cursor pointer to make it clear it's clickable*/
}
{/*>*/
}
{/*    <i className="bi bi-download"></i>*/
}
{/*    <LoaderButton*/
}
{/*        loading={this.state.processing}*/
}
{/*        label={"Download Certificate"}*/
}
{/*        cssName={"btn button_theme"}*/
}
{/*    />*/
}
{/*</div>*/
}